@font-face {
  font-family: "dolce_vita_heavybold";
  src: url("./assets/fonts/dolce_vita_heavy_bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/dolce_vita_heavy_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dolce_vita_lightregular";
  src: url("./assets/fonts/dolce_vita_light-webfont.woff2") format("woff2"),
    url("./assets/fonts/dolce_vita_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dolce_vitaregular";
  src: url("./assets/fonts/dolce_vita-webfont.woff2") format("woff2"),
    url("./assets/fonts/dolce_vita-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
}

img {
  -webkit-user-drag: none;
}

.content-components {
  max-width: 1400px;
  width: 100%;
  margin: auto;
}

.content-components .content-inner {
  width: 92%;
  margin: 0 auto;
}

/* .home,
.about,
.calender,
.clothing,
.isedol,
.products,
.team {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
} */
