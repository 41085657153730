.modal-open {
    position: fixed;
    right: 15%;
    left: 15%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: rgb(0 0 0 / 80%);
    border-radius: 15px;
    z-index: 99;
    overflow: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    animation: slideup 0.2s;
    -moz-animation: slideup 0.2s;
    -webkit-animation: slideup 0.2s;
    -o-animation: slideup 0.2s;
    animation-fill-mode: forwards;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  .modal-close {
    position: fixed;
    right: 15%;
    left: 15%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: rgb(0 0 0 / 80%);
    border-radius: 15px;
    z-index: 99;
    overflow: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    animation: slidedown 0.2s;
    -moz-animation: slidedown 0.2s;
    -webkit-animation: slidedown 0.2s;
    -o-animation: slidedown 0.2s;
    animation-fill-mode: forwards;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  @keyframes slideup {
    from {
        top: 100%;
    }
    to {
      top: 10%;
    }
}
  @-webkit-keyframes slideup { /* Safari and Chrome */
    from {
      top: 100%;
    }
    to {
      top: 10%;
    }
}
@keyframes slidedown {
  from {
      top: 10%;
  }
  to {
    top: 100%;
  }
}
@-webkit-keyframes slidedown { /* Safari and Chrome */
  from {
    top: 10%;
}
to {
  top: 100%;
}
}

.modal-open::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
.modal-close::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
.modalclose {
  font-size: 25px;
  font-weight: 400;
  color: rgb(131, 131, 131);
  text-align: center;
  top: 0px;
  height: 40px;
  line-height: 40px;
  position: sticky;
}

.merchamodal img{
  width: 100%;
  display: block;
  margin: 0 auto;
}

.merchamodal video{
  width: 100%;
  display: block;
}

@media (max-width: 1320px) {
  .modal-open {
    right: 0%;
    left: 0%;
  }
  .modal-close {
    right: 0%;
    left: 0%;
  }
}