.home{
  background-color: black;
  color: #ffffff;
}

.showcase {
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showcase video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.8;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgb(0 0 0) 0%, rgb(0 0 0 / 50%) 35%, rgb(0 0 0 / 60%) 100%);
}

.vidText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  color: #ffffff;
  animation: fadein 2.5s;
  -moz-animation: fadein 2.5s; /* Firefox */
  -webkit-animation: fadein 2.5s; /* Safari and Chrome */
  -o-animation: fadein 2.5s; /* Opera */
  z-index: 9;
}

.vidText h2 {
  font-size: 5em;
  font-weight: 800;
}

.vidText h3 {
  font-size: 3em;
  line-height: 2em;
}

.card-title {
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
}

.intro-dehuv{
  color: #ffffff;
  padding: 10% 0;
  position: relative;
}

.intro-dehuv .shame-dehuv{
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.intro-dehuv .shame-column{
  width: 50%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.shame-back{
  background-image: url("../assets/dehuv-back.png");
}

.intro-dehuv .value-dehuv{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
  text-align: center;
}

.intro-dehuv .value-column{
  width: 33%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.intro-dehuv h1{
  font-size: 65px;
  font-weight: 700;
  line-height: 1.3;
}

.intro-dehuv h2{
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
}

.intro-dehuv h3{
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
  color: rgb(148, 148, 148);
}

.earth{
  position: absolute;
  top: 35%;
  max-width: 550px;
  right: 0;
}
.earth img{
  width: 100%;
}

@media (max-width: 1320px) {
  .vidText h3 {
    font-size: 28px;
  }
  .intro-dehuv{
    padding: 20% 0;
  }
  .intro-dehuv h1{
    font-size: 40px;
  }
  .intro-dehuv h2{
    font-size: 28px;
  }
  .intro-dehuv h3{
    font-size: 20px;
  }
  .intro-dehuv .shame-dehuv{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  .intro-dehuv .shame-column{
    width: 100%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .earth{
    top: 40%;
    opacity: 0.8;
  }
  .card-title {
    font-size: 30px;
  }
  .intro-dehuv .value-dehuv{
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .intro-dehuv .value-column{
    width: 100%;
  }
}

.timetop {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  font-family: 'SBAggroL';
}
.timecontainer {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  color: #fff;
}
.timeline {
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  color: #fff;
}

.timeline ul {
  list-style: none;
  color: #fff;
  padding-left: 0px;
}
.timeline ul li {
  padding: 20px 0;
  border-radius: 10px;
  margin-bottom: 20px;
}
.timeline ul li:last-child {
  margin-bottom: 0;
}
.timeline-content h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}
.timeline-content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}
.timeline-content .date {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
@media (max-width: 747px) {
  .timecontainer {
    padding: 20px 0;
  }
}
@media only screen and (min-width: 747px) {

  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: #fff;
  }
  .timeline ul li {
    width: 50%;
    position: relative;
    margin-bottom: 50px;
  }
  .timeline ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-30px);
    border-radius: 20px 0px 20px 20px;
    text-align: right;
  }
  .timeline ul li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(30px);
    border-radius: 0px 20px 20px 20px;
  }
  .timeline ul li::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    top: 0px;
  }
  .timeline ul li:nth-child(odd)::before {
    transform: translate(50%, -50%);
    right: -30px;
  }
  .timeline ul li:nth-child(even)::before {
    transform: translate(-50%, -50%);
    left: -30px;
  }
  .timeline-content .date {
    font-size: 20px;
  }
  .timeline ul li:hover::before {
    background-color: #549bcf;
  }
}