@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.sct {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dehuv-card {
  margin-top:60px;
  width: 48%;
}

.card-list{
  position: relative;
  background-color: rgba(60, 60, 60, 0.805);
  border-radius: 30px;
  transition: all 0.2s linear;
  overflow: hidden;
}

.card-list h2{
  font-size: 24px;
  color: #fff;
  padding-bottom: 10px;
}

.card-list h3{
  font-size: 18px;
  color: rgb(192, 192, 192);
  padding-bottom: 30px;
}

.card-list p{
  font-size: 17px;
  color: #999;
}


.card-list .card-text {
  padding: 30px;
}

.card-list .card-img {
  overflow: hidden;
}

.card-list .card-img img{
  display: block;
  height: 100%;
  width: 100%;
}

.card-list:hover  {
  transform:  translate( 0%, -3% );
}

@media (max-width: 1320px) {
  .dehuv-card {
    width: 100%;
  }

  .card-list h2{
    font-size: 20px;
  }
  
  .card-list h3{
    font-size: 14px;
  }
  
  .card-list p{
    font-size: 13px;
  }
  
}
