.navbar {
  position: fixed;
  width: 100%;
  background: transparent;
  height: 70px;
  font-size: 15px;
  z-index: 99;
  top: 0;
  transition: all 0.3s ease 0s;
}

.navbar.active {
  height: 60px;
  background: rgba(255, 255, 255, 0.45);
  color: rgb(0, 0, 0);
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 5%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.navbar-contant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar-logo h3{
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 27px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 2px;
}

.navbar-logo p{
  color: rgb(163, 163, 163);
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  /* grid-template-columns: repeat(5, auto); */
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
}

.nav-item {
  display: flex;
  position: relative;
  align-items: center;
  height: 60px;
}

.nav-links {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  line-height: 30px;
}

.nav-links:hover{
  background: rgba(2,32,71,.05);
}

.navbar-dark {
  position: fixed;
  width: 100%;
  background: transparent;
  height: 70px;
  font-size: 15px;
  z-index: 99;
  top: 0;
  transition: all 0.3s ease 0s;
}

.navbar-dark.active {
  height: 60px;
  background: rgba(0, 0, 0, 0.45);
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 5%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.navbar-dark .navbar-logo h3 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 27px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 2px;
}

.navbar-dark .navbar-logo p{
  color: rgb(209, 209, 209);
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
}

.navbar-dark .nav-links {
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  line-height: 30px;
}

.navbar-dark .nav-links:hover{
  background: rgba(223, 223, 223, 0.328);
}


.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar.open {
  height: 300px;
  transition: all 0.3s ease 0s;
}

@media screen and (max-width: 1320px) {
  .fa-caret-down {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    width: 30%;
    height: 100vh;
    position: absolute;
    top: 0px;
    right: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgb(255 255 255);
    color: rgb(0, 0, 0);
    right: 0;
    opacity: 1;
    box-shadow: -4px 0px 20px 6px rgb(0 0 0 / 17%);
    transition: all 0.5s ease;
    font-size: 20px;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .menu-icon {
    font-size: 1.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    z-index: 99;
  }

  .fa-times {
    color: #555;
    font-size: 2rem;
  }

  .fa-bars {
    color: #555;
    font-size: 2rem;
  }

  .navbar-dark .fa-times {
    color: rgb(255, 255, 255);
    font-size: 2rem;
  }

  .navbar-dark .fa-bars {
    color: rgb(255, 255, 255);
    font-size: 2rem;
  }

  .navbar-dark .nav-menu.active {
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    right: 0;
    opacity: 1;
    box-shadow: -4px 0px 20px 6px rgb(0 0 0 / 17%);
    transition: all 0.5s ease;
    font-size: 20px;
    z-index: 1;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}
@media (max-width: 580px) {
  .nav-menu {
    width: 50%;
  }
}