@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@font-face {
  font-family: "dolce_vita_heavybold";
  src: url(/static/media/dolce_vita_heavy_bold-webfont.9beb9a39.woff2) format("woff2"),
    url(/static/media/dolce_vita_heavy_bold-webfont.64bad7a1.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dolce_vita_lightregular";
  src: url(/static/media/dolce_vita_light-webfont.4d982401.woff2) format("woff2"),
    url(/static/media/dolce_vita_light-webfont.6489a8e1.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dolce_vitaregular";
  src: url(/static/media/dolce_vita-webfont.e5479e4f.woff2) format("woff2"),
    url(/static/media/dolce_vita-webfont.3a5bc2c7.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
}

img {
  -webkit-user-drag: none;
}

.content-components {
  max-width: 1400px;
  width: 100%;
  margin: auto;
}

.content-components .content-inner {
  width: 92%;
  margin: 0 auto;
}

/* .home,
.about,
.calender,
.clothing,
.isedol,
.products,
.team {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
} */

.navbar {
  position: fixed;
  width: 100%;
  background: transparent;
  height: 70px;
  font-size: 15px;
  z-index: 99;
  top: 0;
  transition: all 0.3s ease 0s;
}

.navbar.active {
  height: 60px;
  background: rgba(255, 255, 255, 0.45);
  color: rgb(0, 0, 0);
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 5%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.navbar-contant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar-logo h3{
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 27px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 2px;
}

.navbar-logo p{
  color: rgb(163, 163, 163);
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  /* grid-template-columns: repeat(5, auto); */
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
}

.nav-item {
  display: flex;
  position: relative;
  align-items: center;
  height: 60px;
}

.nav-links {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  line-height: 30px;
}

.nav-links:hover{
  background: rgba(2,32,71,.05);
}

.navbar-dark {
  position: fixed;
  width: 100%;
  background: transparent;
  height: 70px;
  font-size: 15px;
  z-index: 99;
  top: 0;
  transition: all 0.3s ease 0s;
}

.navbar-dark.active {
  height: 60px;
  background: rgba(0, 0, 0, 0.45);
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 5%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.navbar-dark .navbar-logo h3 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 27px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 2px;
}

.navbar-dark .navbar-logo p{
  color: rgb(209, 209, 209);
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
}

.navbar-dark .nav-links {
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  line-height: 30px;
}

.navbar-dark .nav-links:hover{
  background: rgba(223, 223, 223, 0.328);
}


.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar.open {
  height: 300px;
  transition: all 0.3s ease 0s;
}

@media screen and (max-width: 1320px) {
  .fa-caret-down {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    width: 30%;
    height: 100vh;
    position: absolute;
    top: 0px;
    right: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgb(255 255 255);
    color: rgb(0, 0, 0);
    right: 0;
    opacity: 1;
    box-shadow: -4px 0px 20px 6px rgb(0 0 0 / 17%);
    transition: all 0.5s ease;
    font-size: 20px;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .menu-icon {
    font-size: 1.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    z-index: 99;
  }

  .fa-times {
    color: #555;
    font-size: 2rem;
  }

  .fa-bars {
    color: #555;
    font-size: 2rem;
  }

  .navbar-dark .fa-times {
    color: rgb(255, 255, 255);
    font-size: 2rem;
  }

  .navbar-dark .fa-bars {
    color: rgb(255, 255, 255);
    font-size: 2rem;
  }

  .navbar-dark .nav-menu.active {
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    right: 0;
    opacity: 1;
    box-shadow: -4px 0px 20px 6px rgb(0 0 0 / 17%);
    transition: all 0.5s ease;
    font-size: 20px;
    z-index: 1;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}
@media (max-width: 580px) {
  .nav-menu {
    width: 50%;
  }
}
.home{
  background-color: black;
  color: #ffffff;
}

.showcase {
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showcase video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.8;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgb(0 0 0) 0%, rgb(0 0 0 / 50%) 35%, rgb(0 0 0 / 60%) 100%);
}

.vidText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  color: #ffffff;
  animation: fadein 2.5s;
  -moz-animation: fadein 2.5s; /* Firefox */
  -webkit-animation: fadein 2.5s; /* Safari and Chrome */
  -o-animation: fadein 2.5s; /* Opera */
  z-index: 9;
}

.vidText h2 {
  font-size: 5em;
  font-weight: 800;
}

.vidText h3 {
  font-size: 3em;
  line-height: 2em;
}

.card-title {
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
}

.intro-dehuv{
  color: #ffffff;
  padding: 10% 0;
  position: relative;
}

.intro-dehuv .shame-dehuv{
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.intro-dehuv .shame-column{
  width: 50%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.shame-back{
  background-image: url(/static/media/dehuv-back.d63e337c.png);
}

.intro-dehuv .value-dehuv{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
  text-align: center;
}

.intro-dehuv .value-column{
  width: 33%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.intro-dehuv h1{
  font-size: 65px;
  font-weight: 700;
  line-height: 1.3;
}

.intro-dehuv h2{
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
}

.intro-dehuv h3{
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
  color: rgb(148, 148, 148);
}

.earth{
  position: absolute;
  top: 35%;
  max-width: 550px;
  right: 0;
}
.earth img{
  width: 100%;
}

@media (max-width: 1320px) {
  .vidText h3 {
    font-size: 28px;
  }
  .intro-dehuv{
    padding: 20% 0;
  }
  .intro-dehuv h1{
    font-size: 40px;
  }
  .intro-dehuv h2{
    font-size: 28px;
  }
  .intro-dehuv h3{
    font-size: 20px;
  }
  .intro-dehuv .shame-dehuv{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  .intro-dehuv .shame-column{
    width: 100%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .earth{
    top: 40%;
    opacity: 0.8;
  }
  .card-title {
    font-size: 30px;
  }
  .intro-dehuv .value-dehuv{
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .intro-dehuv .value-column{
    width: 100%;
  }
}

.timetop {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  font-family: 'SBAggroL';
}
.timecontainer {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  color: #fff;
}
.timeline {
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  color: #fff;
}

.timeline ul {
  list-style: none;
  color: #fff;
  padding-left: 0px;
}
.timeline ul li {
  padding: 20px 0;
  border-radius: 10px;
  margin-bottom: 20px;
}
.timeline ul li:last-child {
  margin-bottom: 0;
}
.timeline-content h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}
.timeline-content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}
.timeline-content .date {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
@media (max-width: 747px) {
  .timecontainer {
    padding: 20px 0;
  }
}
@media only screen and (min-width: 747px) {

  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: #fff;
  }
  .timeline ul li {
    width: 50%;
    position: relative;
    margin-bottom: 50px;
  }
  .timeline ul li:nth-child(odd) {
    float: left;
    clear: right;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    border-radius: 20px 0px 20px 20px;
    text-align: right;
  }
  .timeline ul li:nth-child(even) {
    float: right;
    clear: left;
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    border-radius: 0px 20px 20px 20px;
  }
  .timeline ul li::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    top: 0px;
  }
  .timeline ul li:nth-child(odd)::before {
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    right: -30px;
  }
  .timeline ul li:nth-child(even)::before {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: -30px;
  }
  .timeline-content .date {
    font-size: 20px;
  }
  .timeline ul li:hover::before {
    background-color: #549bcf;
  }
}
.sct {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dehuv-card {
  margin-top:60px;
  width: 48%;
}

.card-list{
  position: relative;
  background-color: rgba(60, 60, 60, 0.805);
  border-radius: 30px;
  transition: all 0.2s linear;
  overflow: hidden;
}

.card-list h2{
  font-size: 24px;
  color: #fff;
  padding-bottom: 10px;
}

.card-list h3{
  font-size: 18px;
  color: rgb(192, 192, 192);
  padding-bottom: 30px;
}

.card-list p{
  font-size: 17px;
  color: #999;
}


.card-list .card-text {
  padding: 30px;
}

.card-list .card-img {
  overflow: hidden;
}

.card-list .card-img img{
  display: block;
  height: 100%;
  width: 100%;
}

.card-list:hover  {
  -webkit-transform:  translate( 0%, -3% );
          transform:  translate( 0%, -3% );
}

@media (max-width: 1320px) {
  .dehuv-card {
    width: 100%;
  }

  .card-list h2{
    font-size: 20px;
  }
  
  .card-list h3{
    font-size: 14px;
  }
  
  .card-list p{
    font-size: 13px;
  }
  
}

.modal-open {
    position: fixed;
    right: 15%;
    left: 15%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: rgb(0 0 0 / 80%);
    border-radius: 15px;
    z-index: 99;
    overflow: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    animation: slideup 0.2s;
    -moz-animation: slideup 0.2s;
    -webkit-animation: slideup 0.2s;
    -o-animation: slideup 0.2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  .modal-close {
    position: fixed;
    right: 15%;
    left: 15%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: rgb(0 0 0 / 80%);
    border-radius: 15px;
    z-index: 99;
    overflow: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    animation: slidedown 0.2s;
    -moz-animation: slidedown 0.2s;
    -webkit-animation: slidedown 0.2s;
    -o-animation: slidedown 0.2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  @keyframes slideup {
    from {
        top: 100%;
    }
    to {
      top: 10%;
    }
}
  @-webkit-keyframes slideup { /* Safari and Chrome */
    from {
      top: 100%;
    }
    to {
      top: 10%;
    }
}
@keyframes slidedown {
  from {
      top: 10%;
  }
  to {
    top: 100%;
  }
}
@-webkit-keyframes slidedown { /* Safari and Chrome */
  from {
    top: 10%;
}
to {
  top: 100%;
}
}

.modal-open::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
.modal-close::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
.modalclose {
  font-size: 25px;
  font-weight: 400;
  color: rgb(131, 131, 131);
  text-align: center;
  top: 0px;
  height: 40px;
  line-height: 40px;
  position: -webkit-sticky;
  position: sticky;
}

.merchamodal img{
  width: 100%;
  display: block;
  margin: 0 auto;
}

.merchamodal video{
  width: 100%;
  display: block;
}

@media (max-width: 1320px) {
  .modal-open {
    right: 0%;
    left: 0%;
  }
  .modal-close {
    right: 0%;
    left: 0%;
  }
}
.mainImg {
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/pickus_back.8d04e673.png);
  background-size: cover;
  background-position: 65% 0;
}

.mainText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  color: #444444;
  animation: fadein 2.5s;
  -moz-animation: fadein 2.5s; /* Firefox */
  -webkit-animation: fadein 2.5s; /* Safari and Chrome */
  -o-animation: fadein 2.5s; /* Opera */
}

.mainText h2 {
  font-size: 80px;
  font-weight: 800;
}

.mainText h2 span{
  color:#58ba97;
}

.mainText h3 {
  font-size: 50px;
  line-height: 50px;
  font-weight: 800;
}

.mainText h4 {
  font-size: 30px;
  line-height: 4em;
  font-weight: 400;
}

.mainvideo {
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-container {
  position: absolute;
  bottom: 97px;
  left: 50%;
  width: 25px;
  cursor: pointer;
  height: 20px;
  -webkit-animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
          animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.arrow-down {
  height: 6px;
  background: #808080;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  border-radius: 5px;
}

.arrow-down:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 100%;
  border-radius: 5px;
  background: #808080;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}

@-webkit-keyframes bounce {
50% {
  bottom: 120px;
  }
}

@keyframes bounce {
50% {
  bottom: 120px;
  }
}

.intro-pickus {
  display: flex;
  height: 500px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: pre-wrap;
}

.intro-pickus h1 {
  font-size: 38px;
  font-weight: 700;
  line-height: 1.6;
  color: #191f28;
  text-align: center;
}

.info-app-pickus {
  padding: 10% 0;
  position: relative;
  background: #f5f5f5;
}

.info-app-pickus:nth-child(even) {
  background: #fff;
}

.info-app-pickus .phone-column{
  position: relative;
  height: 1272px;
}

.info-app-pickus .carousel-column{
  position: relative;
}

.info-app-pickus .ai-column{
  display: flex;
  position: relative;
  height: 945px;
  flex-direction: column;
  justify-content: space-between;
}


.info-app-pickus h1 {
  font-size: 55px;
  font-weight: 700;
  line-height: 1.3;
  color: #191f28;
}

.info-app-pickus h2 {
  
  font-size: 30px;
  font-weight: 700;
  line-height: 2.6;
  color: #58ba97;
}

.info-app-pickus .sub-text {
  position: absolute;
  bottom: 165px;
  left: 550px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.6;
  color: #3f3f3f;
}

.info-app-pickus .ai-text {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.6;
  color: #3f3f3f;
}

.info-app-pickus video {
  padding-top: 50px;
  width: 100%;
}

.phone {
  max-width: 450px;
  width: 100%;
}

.phone:nth-child(even) {
  position: absolute;
  bottom: 0;
  left: 0;
}

.phone:nth-child(odd) {
  position: absolute;
  top: 0;
  right: 0;
}

.phone p{
  position: absolute;
  top: 50%;
  left: 20%;
}

.info-app-pickus .phone img{
  width: 100%;
  position: relative;
  z-index: 2;
}

.info-app-pickus .panel{
  padding: 30px 0;
  max-width: 450px;
  width: 100%;
}

.info-app-pickus .panel img{
  width: 100%;
  position: relative;
  z-index: 2;
}

.phone video {
  position: absolute;
  z-index: 0;
  top: 20px;
  right: 34px;
  width: 84%;
}

.ai-text p{
  padding-bottom: 30%;
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@media (max-width: 1320px) {
  .mainText h2 {
    font-size: 40px;
  }
  .mainText h3 {
    font-size: 30px;
  }
  .mainText h4 {
    font-size: 20px;
  }
  .intro-pickus {
    padding: 10%;
    height: 100%;
  }
  
  .intro-pickus h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.6;
  }
  .info-app-pickus h1 {
    font-size: 25px;
  }
  
  .info-app-pickus h2 {
    
    font-size: 22px;
  }
  .phone {
    position: relative !important;
    padding: 30px 0;
    margin: 0 auto;
    max-width: 390px;
  }
  .phone video {
    top: 40px;
    right: 30px;
  }
  .info-app-pickus .panel{
    max-width: 300px;
  }
  .info-app-pickus .phone-column{
    height: 100%;
  }
  .info-app-pickus .ai-column{
    height: 100%;
  }
  .info-app-pickus .sub-text {
    position: relative !important;
    bottom: 0;
    left: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    color: #3f3f3f;
  }
  .info-app-pickus .ai-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    color: #3f3f3f;
  }
  .ai-text p{
    padding-bottom: 0%;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: #222;
}

.footer .first {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  color: aliceblue;
}

.footer .first .logoName {
  font-size: 20px;
}

.footer .first .contact {
  text-align: right;
  font-size: 20px;
}

.footer .first .discord {
  display: flex;
  font-size: 30px;
  align-items: center;
}

.discord .discordIcon {
  padding-right: 5px;
  height: 25px;
}
.footer .first .email {
  text-align: right;
  font-size: 13px;
}

.footer hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

.secondfooter {
  color: aliceblue;
}

@media (max-width: 1320px) {
  .footer .first {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer .first .contact {
    padding-top: 60px;
    text-align: left;
  }
  .footer .first .email {
    text-align: left;
  }
}
