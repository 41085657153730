.mainImg {
  width: 100%;
  height: 100vh;
  background-image: url('../assets/pickus_back.png');
  background-size: cover;
  background-position: 65% 0;
}

.mainText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  color: #444444;
  animation: fadein 2.5s;
  -moz-animation: fadein 2.5s; /* Firefox */
  -webkit-animation: fadein 2.5s; /* Safari and Chrome */
  -o-animation: fadein 2.5s; /* Opera */
}

.mainText h2 {
  font-size: 80px;
  font-weight: 800;
}

.mainText h2 span{
  color:#58ba97;
}

.mainText h3 {
  font-size: 50px;
  line-height: 50px;
  font-weight: 800;
}

.mainText h4 {
  font-size: 30px;
  line-height: 4em;
  font-weight: 400;
}

.mainvideo {
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-container {
  position: absolute;
  bottom: 97px;
  left: 50%;
  width: 25px;
  cursor: pointer;
  height: 20px;
  animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transform: translateX(-50%);
}

.arrow-down {
  height: 6px;
  background: #808080;
  transform: rotate(45deg);
  transform-origin: 0% 0%;
  border-radius: 5px;
}

.arrow-down:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 100%;
  border-radius: 5px;
  background: #808080;
  transform: rotate(-90deg);
  transform-origin: 0% 100%;
}

@keyframes bounce {
50% {
  bottom: 120px;
  }
}

.intro-pickus {
  display: flex;
  height: 500px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: pre-wrap;
}

.intro-pickus h1 {
  font-size: 38px;
  font-weight: 700;
  line-height: 1.6;
  color: #191f28;
  text-align: center;
}

.info-app-pickus {
  padding: 10% 0;
  position: relative;
  background: #f5f5f5;
}

.info-app-pickus:nth-child(even) {
  background: #fff;
}

.info-app-pickus .phone-column{
  position: relative;
  height: 1272px;
}

.info-app-pickus .carousel-column{
  position: relative;
}

.info-app-pickus .ai-column{
  display: flex;
  position: relative;
  height: 945px;
  flex-direction: column;
  justify-content: space-between;
}


.info-app-pickus h1 {
  font-size: 55px;
  font-weight: 700;
  line-height: 1.3;
  color: #191f28;
}

.info-app-pickus h2 {
  
  font-size: 30px;
  font-weight: 700;
  line-height: 2.6;
  color: #58ba97;
}

.info-app-pickus .sub-text {
  position: absolute;
  bottom: 165px;
  left: 550px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.6;
  color: #3f3f3f;
}

.info-app-pickus .ai-text {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.6;
  color: #3f3f3f;
}

.info-app-pickus video {
  padding-top: 50px;
  width: 100%;
}

.phone {
  max-width: 450px;
  width: 100%;
}

.phone:nth-child(even) {
  position: absolute;
  bottom: 0;
  left: 0;
}

.phone:nth-child(odd) {
  position: absolute;
  top: 0;
  right: 0;
}

.phone p{
  position: absolute;
  top: 50%;
  left: 20%;
}

.info-app-pickus .phone img{
  width: 100%;
  position: relative;
  z-index: 2;
}

.info-app-pickus .panel{
  padding: 30px 0;
  max-width: 450px;
  width: 100%;
}

.info-app-pickus .panel img{
  width: 100%;
  position: relative;
  z-index: 2;
}

.phone video {
  position: absolute;
  z-index: 0;
  top: 20px;
  right: 34px;
  width: 84%;
}

.ai-text p{
  padding-bottom: 30%;
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}
@media (max-width: 1320px) {
  .mainText h2 {
    font-size: 40px;
  }
  .mainText h3 {
    font-size: 30px;
  }
  .mainText h4 {
    font-size: 20px;
  }
  .intro-pickus {
    padding: 10%;
    height: 100%;
  }
  
  .intro-pickus h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.6;
  }
  .info-app-pickus h1 {
    font-size: 25px;
  }
  
  .info-app-pickus h2 {
    
    font-size: 22px;
  }
  .phone {
    position: relative !important;
    padding: 30px 0;
    margin: 0 auto;
    max-width: 390px;
  }
  .phone video {
    top: 40px;
    right: 30px;
  }
  .info-app-pickus .panel{
    max-width: 300px;
  }
  .info-app-pickus .phone-column{
    height: 100%;
  }
  .info-app-pickus .ai-column{
    height: 100%;
  }
  .info-app-pickus .sub-text {
    position: relative !important;
    bottom: 0;
    left: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    color: #3f3f3f;
  }
  .info-app-pickus .ai-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    color: #3f3f3f;
  }
  .ai-text p{
    padding-bottom: 0%;
  }
}